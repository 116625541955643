<template>
	<main>
		<!-- breadcrumb-area -->
		<section>
			<img src="assets/img/bg/eximax.jpg" class="img-fluid" />
		</section>

		<section class="about-area pb-60 pt-100">
			<div class="container">
				<!-- START TABS DIV -->
				<div class="tabbable-responsive">
					<div class="tabbable">
						<ul class="nav nav-tabs" id="myTab" role="tablist">
							<li class="nav-item">
								<a
									class="nav-link active"
									id="first-tab"
									data-toggle="tab"
									href="#first"
									role="tab"
									aria-controls="first"
									aria-selected="true"
									>Features</a
								>
							</li>
							<li class="nav-item">
								<a
									class="nav-link"
									id="second-tab"
									data-toggle="tab"
									href="#second"
									role="tab"
									aria-controls="second"
									aria-selected="false"
									>Integrations</a
								>
							</li>
							<li class="nav-item">
								<a
									class="nav-link"
									id="third-tab"
									data-toggle="tab"
									href="#third"
									role="tab"
									aria-controls="third"
									aria-selected="false"
									>Live Demo</a
								>
							</li>
							<li class="nav-item">
								<a
									class="nav-link"
									id="fourth-tab"
									data-toggle="tab"
									href="#fourth"
									role="tab"
									aria-controls="fourth"
									aria-selected="false"
									>Customers</a
								>
							</li>
							<li class="nav-item">
								<a
									class="nav-link"
									id="fifth-tab"
									data-toggle="tab"
									href="#fifth"
									role="tab"
									aria-controls="fifth"
									aria-selected="false"
									>Pricing</a
								>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<!--end of tab header-->

			<div class="container">
				<div class="tab-content">
					<div
						class="tab-pane fade show active"
						id="first"
						role="tabpanel"
						aria-labelledby="first-tab"
					>
						<div class="content_wrap">
							<section class="about-area">
								<div class="row">
									<div class="col-lg-6 col-xs-12">
										<div class="section-title pb-15">
											<h4>Consignment (Job) Management</h4>
										</div>
										<div class="about-content pl-30">
											<ul class="feature-content">
												<li>
													Create consignments for Import, Export and Domestic
													Jobs.
												</li>
												<li>
													Co-relate consignments with inward and outward
													billings.
												</li>
												<li>
													Attach documents to each consignment for future
													reference.
												</li>
												<li>
													Manage bill of entries and shipping bills
												</li>
												<li>
													Allocate container no(s)., size and seal no. for each
													consignment.
												</li>
											</ul>
										</div>
									</div>
									<div class="col-lg-6 col-xs-12">
										<img
											src="assets/img/products/consignment.jpg"
											alt="Consignment (Job) Management"
											class="img-fluid"
										/>
									</div>
								</div>
							</section>
						</div>

						<div class="content_wrap mt-40">
							<section class="about-area">
								<div class="row">
									<div class="col-lg-6 col-xs-12">
										<img
											src="assets/img/products/transportation.jpg"
											alt="Consignment (Job) Management"
											class="img-fluid"
										/>
									</div>
									<div class="col-lg-6 col-xs-12">
										<div class="section-title pb-15">
											<h4>Transportation Management</h4>
										</div>
										<div class="about-content">
											<ul class="feature-content pl-30">
												<li>
													Manage by-road trips for cargo movements for import,
													export and domestic consignments
												</li>
												<li>
													Even manage trips solely for transportation purpose
												</li>
												<li>
													Manage Driver and Diesel Advance
												</li>
												<li>
													Segregate profits on own as well as hired vehicles.
												</li>
											</ul>
										</div>
									</div>
								</div>
							</section>
						</div>

						<div class="content_wrap mt-40">
							<section class="about-area">
								<div class="row">
									<div class="col-lg-6 col-xs-12">
										<div class="section-title pb-15">
											<h4>Cost Management</h4>
										</div>

										<ul class="feature-content pl-30">
											<li>
												Create cost centres to manage cost across consignments
											</li>
											<li>
												Book expenses for individual cost centres against each
												consignment
											</li>
											<li>
												Get reminded on bills pending based on expenses incurred
											</li>
											<li>
												Manage inward bills and their payments
											</li>
											<li>
												Generate payable freight statements for by-road
												transportation
											</li>
										</ul>
									</div>
									<div class="col-lg-6 col-xs-12 text-right">
										<img
											src="assets/img/products/cost.jpg"
											alt="Cost Management"
											class="img-fluid"
										/>
									</div>
								</div>
							</section>
						</div>

						<div class="content_wrap mt-40">
							<section class="about-area">
								<div class="row">
									<div class="col-xl-6 col-lg-6 col-xs-12 text-left">
										<img
											src="assets/img/products/bill.jpg"
											alt="Bill Management"
											class="img-fluid"
										/>
									</div>
									<div class="col-xl-6 col-lg-6 col-xs-12">
										<div class="section-title pb-15">
											<h4>Bill Management</h4>
										</div>
										<div class="about-content">
											<ul class="feature-content pl-30">
												<li>
													Create debit note, invoice or debit note cum invoice
													for individual consignments
												</li>
												<li>
													Generate bills with const centre details or hide by
													grouping them to cost centre groups
												</li>
												<li>
													Get a hint on expenses incurred for each cost centre
													before generating bill
												</li>
												<li>
													Generate brokerage invoice automatically based on
													ocean freight entered
												</li>
												<li>
													Generate freight invoice automatically based on trips
													entered
												</li>
												<li>
													Automatic calculation of service tax
												</li>
											</ul>
										</div>
									</div>
								</div>
							</section>
						</div>

						<div class="content_wrap mt-40">
							<section class="about-area">
								<div class="row">
									<div class="col-xl-6 col-lg-6 col-xs-12">
										<div class="section-title pb-15">
											<h4>MIS Masters</h4>
										</div>
										<div class="about-content">
											<ul class="feature-content pl-30">
												<li>
													Cost centres
												</li>
												<li>
													Commodity masters
												</li>
												<li>
													Vehicle and vehicle groups
												</li>
												<li>
													Set reminders relevant to vehicles i.e. due dates of
													insurance, installment etc.
												</li>
												<li>
													Loading stations and destinations
												</li>
												<li>
													Party, transporter and pump accounts with contact
													management
												</li>
												<li>
													Diesel rate masters
												</li>
											</ul>
										</div>
									</div>
									<div class="col-xl-6 col-lg-6 col-xs-12 text-right">
										<img
											src="assets/img/products/mismanagement.jpg"
											alt="MIS Masters"
											class="img-fluid"
										/>
									</div>
								</div>
							</section>
						</div>

						<div class="content_wrap mt-40">
							<section class="about-area">
								<div class="row">
									<div class="col-xl-6 col-lg-6 col-xs-12 text-left">
										<img
											src="assets/img/products/accounting.jpg"
											alt="Accounting Masters"
											class="img-fluid"
										/>
									</div>
									<div class="col-xl-6 col-lg-6 col-xs-12">
										<div class="section-title pb-15">
											<h4>Accounting Masters</h4>
										</div>
										<div class="about-content">
											<ul class="feature-content pl-30">
												<li>
													Unified accounting groups
												</li>
												<li>
													Maintain general ledgers/sub ledgers with contacts
													management
												</li>
												<li>
													Unlimited classification of accounting masters
												</li>
											</ul>
										</div>
									</div>
								</div>
							</section>
						</div>

						<div class="content_wrap mt-40">
							<section class="about-area">
								<div class="row">
									<div class="col-xl-6 col-lg-6 col-xs-12">
										<div class="section-title pb-15">
											<h4>Financial Accounting</h4>
										</div>
										<div class="about-content">
											<ul class="feature-content pl-30">
												<li>
													Manage receipts, payments, journals and contras
												</li>
												<li>
													Manage expenses and credit notes
												</li>
												<li>
													Manage cash and fund flow
												</li>
												<li>
													Bank reconciliation
												</li>
												<li>
													Outstanding receivables and payables
												</li>
												<li>
													Balance sheet
												</li>
												<li>
													Profit & Loss account
												</li>
												<li>
													Trial balance
												</li>
												<li>
													Outstanding statements
												</li>
												<li>
													Voucher printing
												</li>
												<li>
													Cheque printing
												</li>
											</ul>
										</div>
									</div>
									<div class="col-xl-6 col-lg-6 col-xs-12 text-right">
										<img
											src="assets/img/products/financialaccounting.jpg"
											alt="Financial Accounting"
											class="img-fluid"
										/>
									</div>
								</div>
							</section>
						</div>

						<div class="content_wrap mt-40">
							<section class="about-area">
								<div class="row">
									<div class="col-xl-6 col-lg-6 col-xs-12 text-left">
										<img
											src="assets/img/products/reporting.jpg"
											alt="MIS Reports"
											class="img-fluid"
										/>
									</div>
									<div class="col-xl-6 col-lg-6 col-xs-12">
										<div class="section-title pb-15">
											<h4>MIS Reports</h4>
										</div>
										<div class="about-content">
											<ul class="feature-content pl-30">
												<li>
													Brokerage, Freight and Invoice Register
												</li>
												<li>
													Debit and Credit Note Register
												</li>
												<li>
													Purchase, Freight Statement, Receipt, Payment, Journal
													and Contra Register
												</li>
												<li>
													Sales Bills Pending (Cost centre wise)
												</li>
												<li>
													Job wise receipt, payment and comparision report
												</li>
												<li>
													Trip (by-road) and Diesel Register
												</li>
												<li>
													Trip wise income register
												</li>
												<li>
													Consignment status, summary and detailed reports
												</li>
											</ul>
										</div>
									</div>
								</div>
							</section>
						</div>

						<div
							class="content_wrap mt-40"
							style="background-image:url(assets/img/bg/footer-bg-4.jpg);background-repeat: no-repeat;background-size: cover;"
						>
							<section class="about-area speial-feature">
								<div class="row">
									<div class="col-xl-12 col-lg-12 col-xs-12">
										<div class="section-title pb-5">
											<h3>Special Features</h3>
										</div>
										<div class="about-content">
											<p>
												<i class="fa fa-check pr-10" aria-hidden="true"></i
												>Fully customizable to meet your requirement
											</p>

											<p>
												<i
													class="fa fa-check pr-10 pt-15"
													aria-hidden="true"
												></i
												>Multi company generation
											</p>

											<p>
												<i
													class="fa fa-check pr-10 pt-15"
													aria-hidden="true"
												></i
												>Multi user
											</p>

											<p>
												<i
													class="fa fa-check pr-10 pt-15"
													aria-hidden="true"
												></i
												>SMS integration
											</p>

											<p>
												<i
													class="fa fa-check pr-10 pt-15"
													aria-hidden="true"
												></i
												>Option for multi site online synchronization
											</p>

											<p>
												<i
													class="fa fa-check pr-10 pt-15"
													aria-hidden="true"
												></i
												>Option for multi site offline synchronization
											</p>

											<p>
												<i
													class="fa fa-check pr-10 pt-15"
													aria-hidden="true"
												></i
												>Voucher and Report level user based security
											</p>

											<p>
												<i
													class="fa fa-check pr-10 pt-15"
													aria-hidden="true"
												></i
												>Backup and Restore companies
											</p>

											<p>
												<i
													class="fa fa-check pr-10 pt-15"
													aria-hidden="true"
												></i
												>Tally Integration
											</p>

											<p>
												<i
													class="fa fa-check pr-10 pt-15"
													aria-hidden="true"
												></i
												>Directly open vouchers from reports
											</p>

											<p>
												<i
													class="fa fa-check pr-10 pt-15"
													aria-hidden="true"
												></i
												>Print, Email or Export vouchers and reports
											</p>

											<p>
												<i
													class="fa fa-check pr-10 pt-15"
													aria-hidden="true"
												></i
												>Email or Export vouchers and reports in various file
												formats such as PDF, Doc, Docx, Xls, Xlsx etc.
											</p>
										</div>
									</div>
								</div>
							</section>
						</div>

						<div class="content_wrap mt-40">
							<div class="servicee-sidebar mb-50">
								<div class="sidebar-link grey-bg">
									<h3>Excellent Software at Fair Prices</h3>
									<div class="about-content pt-10" style="padding: 25px;">
										<h6>
											<b
												>You pay only once for permanent use of your license!</b
											>
										</h6>

										<P
											><i
												class="fa fa-check-square-o pr-10 pt-30"
												aria-hidden="true"
											></i
											>EXIMax combines all the features required for a complete
											fleet management application in a single software.</P
										>

										<P
											><i
												class="fa fa-check-square-o pr-10 pt-15"
												aria-hidden="true"
											></i
											>And the best of all: A single purchase with permanent use
											and no update requirements</P
										>
										<br />

										<h5 class="pt-15">Investment Security</h5>
										<p>
											<i class="fa fa-check pr-10 pt-30" aria-hidden="true"></i
											>Lifetime licenses – use is possible without time
											restrictions.
										</p>

										<p>
											<i class="fa fa-check pr-10 pt-15" aria-hidden="true"></i
											>1 year free customer support.
										</p>

										<p>
											<i class="fa fa-check pr-10 pt-15" aria-hidden="true"></i
											>1 month free for customization.
										</p>

										<p>
											<i class="fa fa-check pr-10 pt-15" aria-hidden="true"></i
											>Telephonic and online consultation or support via
											competent employees.
										</p>

										<p>
											<i class="fa fa-check pr-10 pt-15" aria-hidden="true"></i
											>Easy and affordable post-warranty subscriptions
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!--end of tab 1-->

					<div
						class="tab-pane fade"
						id="second"
						role="tabpanel"
						aria-labelledby="second-tab"
					>
						<h6 class="pt-15">
							<b>FreightMan works seemlessly with following services</b>
						</h6>
						<br />
						<p>
							<i class="fa fa-check pr-10" aria-hidden="true"></i
							><b>Tally accounting software</b>
						</p>
						<p>
							Import data from tally accounting software or export to the same
						</p>

						<p>
							<i class="fa fa-check pr-10" aria-hidden="true"></i
							><b>Microsoft Office</b>
						</p>
						<p>
							Import data from excel worksheet or export to word, excel, outlook
							express etc.
						</p>

						<p>
							<i class="fa fa-check pr-10" aria-hidden="true"></i
							><b>Acrobat Reader</b>
						</p>
						<p>Export data from EXIMax to create PDF files.</p>
					</div>
					<!--end of tab 2-->
					<div
						class="tab-pane fade"
						id="third"
						role="tabpanel"
						aria-labelledby="third-tab"
					>
						<p>
							If you want us to show you what our software can do for you, Tell
							us something about you and we will gladly arrange a live demo for
							you
						</p>
						<br />
						<div class="row">
							<div class="col-md-7">
								<form
									id="contact-form"
									action="#"
									class="contact-form"
									method="POST"
								>
									<div class="row">
										<div class="col-xl-6 col-lg-6 mb-20">
											<input name="name" type="text" placeholder="Name" />
										</div>
									</div>
									<div class="row">
										<div class="col-xl-6 col-lg-6 mb-20">
											<input name="email" type="email" placeholder="Email" />
										</div>
									</div>
									<div class="row">
										<div class="col-xl-6 col-lg-6 mb-20">
											<input
												name="contact"
												type="contact"
												placeholder="Contact No"
											/>
										</div>
									</div>
									<div class="row">
										<div class="col-xl-6 col-lg-6 mb-20">
											<button class="btn" type="submit">Submit</button>
										</div>
									</div>
								</form>
								<p class="ajax-response"></p>
							</div>
						</div>
					</div>
					<!--end of tab 3-->
					<div
						class="tab-pane fade"
						id="fourth"
						role="tabpanel"
						aria-labelledby="fourth-tab"
					>
						<div class="content_wrap mt-10">
							<section class="about-area">
								<div class="row">
									<div class="col-xl-12 col-lg-12">
										<section class="testimonial-area pt-100 pb-100">
											<div class="container">
												<div class="row">
													<div class="col-xl-12">
														<div class="testimonial-active owl-carousel">
															<div
																class="single-item testimonial-items text-center"
															>
																<div class="client-text">
																	We are using EXIMax since 2008, and we highly
																	recommend anybody who requires an accounting
																	and ERP solution for shipping industry
																</div>
																<h4>Nakoda Forwarders, Gandhidham</h4>
															</div>
															<div
																class="single-item testimonial-items text-center"
															>
																<div class="client-text">
																	We use EXIMax daily to manage our accounts and
																	operations. It's fast and intuitive and does
																	exactly what we need for our shipping company.
																</div>
																<h4>Shree Shipping Services, Gandhidham</h4>
															</div>
														</div>
													</div>
												</div>
											</div>
										</section>
									</div>
								</div>
							</section>
						</div>
					</div>
					<!--end of tab 4-->
					<div
						class="tab-pane fade"
						id="fifth"
						role="tabpanel"
						aria-labelledby="fifth-tab"
					>
						<div class="content_wrap">
							<section class="about-area">
								<div class="row">
									<div class="col-xl-6 col-lg-6">
										<h6><b>Our prizing includes free</b></h6>
										<br />
										<p>
											<i class="fa fa-check pr-10" aria-hidden="true"></i
											>Personalized Training
										</p>

										<p>
											<i class="fa fa-check pr-10" aria-hidden="true"></i
											>Customization for the first month of purchase
										</p>

										<p>
											<i class="fa fa-check pr-10" aria-hidden="true"></i
											>Telephonic/Remote Support for the first year of purchase
										</p>
									</div>
									<div class="col-xl-6 col-lg-6">
										<h6><b>Ask for a Free Trial</b></h6>
										<br />
										<p>
											<i class="fa fa-check pr-10" aria-hidden="true"></i>Get
											satisfied before buying
										</p>

										<p>
											<i class="fa fa-check pr-10" aria-hidden="true"></i>Trial
											includes all the features mentioned herein
										</p>

										<p>
											<i class="fa fa-check pr-10" aria-hidden="true"></i>You
											can retain your data and customization from trial at the
											time of purchase
										</p>
									</div>
								</div>

								<div class="row pt-30">
									<div class="col-xl-12 col-lg-12">
										<table class="table table-bordered table-striped">
											<tr>
												<td>Particulars</td>
												<td>Amount (INR)</td>
											</tr>

											<tr>
												<td>
													<b>Single user</b><br /><br />(for lifetime use)
												</td>
												<td><b>28000/-</b> (per license)</td>
											</tr>

											<tr>
												<td>
													<b>Add-on workstations (local)</b><br /><br />(for
													lifetime use)
												</td>
												<td><b>2000/-</b> (per workstation)</td>
											</tr>

											<tr>
												<td>
													<b>Add-on workstations (remote)</b><br /><br />(for
													lifetime use)
												</td>
												<td><b>5000/-</b> (per workstation)</td>
											</tr>
										</table>
									</div>
								</div>

								<div class="row pt-30">
									<div class="col-lg-12 col-md-12">
										<div class="section-title pb-15">
											<h5>Frequently asked Questions</h5>
										</div>
										<p><b>Q - How can I pay ?</b></p>
										<p>
											We are going to accept online payments very soon, for the
											mean time you can pay through a high value cheque or
											demand draft in favor of Extreme Solutions and payable at
											Gandhidham.
										</p>
										<p>
											You can also use RTGS or NEFT modes of payment, if you
											wish to.
										</p>
									</div>
								</div>

								<div class="row pt-30">
									<div class="col-lg-6 col-md-6 col-xs-12">
										<p><b>Q - Will I have to pay renewal charges ?</b></p>
										<p>No, our prizing are for lifetime use</p>

										<p><b>Q - What is included in the training ?</b></p>
										<p>
											A comprehensive and gradual training program will be held
											by our experienced team member to get you on board.
										</p>
										<p>
											You will get help with each and every module and will also
											get to know some hidden features as you get along.
										</p>

										<p><b>Q - What customization can I request ?</b></p>
										<p>
											Anything, that you find you need it your way, you can ask.
											Most of the customization for the first month of purchase
											will remain free.
										</p>

										<p>
											<b
												>Q - Can I request customization after first month of
												purchase ?</b
											>
										</p>
										<p>
											Yes, you can ask for customization at anytime, however, it
											will be at some prize after the first month of purchase.
										</p>
									</div>
									<div class="col-lg-6 col-md-6 col-xs-12">
										<p>
											<b
												>Q - What will be the charges for customization after
												first month of purchase ?</b
											>
										</p>
										<p>
											It depends on what kind of customization you are asking
											for. We will let you know estimated time and cost for the
											customization at the time of your request.
										</p>

										<p><b>Q - What is telephonic/remote support ?</b></p>
										<p>
											In case if you get stuck somewhere, You will be personally
											attended by one of our team member to guide you on
											telephone or assist you through remote desktop software.
										</p>

										<p>
											<b
												>Q - Will I get support after first year of purchase
												?</b
											>
										</p>
										<p>Yes, but at a prize.</p>

										<p>
											<b
												>Q - What will it cost for telephonic/remote support
												after first year of purchase ?</b
											>
										</p>
										<p>
											Our subscriptions are simple and easy to understand. You
											can buy our annual support contract and enjoy support for
											a year or you can buy support tickets (minimum 10), which
											can be used without any time restrictions.
										</p>
									</div>
								</div>
							</section>
						</div>
					</div>
					<!--end of tab 5-->
				</div>
			</div>
		</section>
	</main>
</template>

<script>
export default {
	name: "eximax",
	mounted() {
		// script for testimonials slider
		$(".testimonial-active").owlCarousel({
			loop: true,
			margin: 10,
			nav: false,
			dots: false,
			autoplay: true,
			responsive: {
				100: {
					items: 1,
				},
				200: {
					items: 1,
				},
				300: {
					items: 1,
				},
			},
		});
	},
};
</script>

<style></style>
